.leftPanel {
  overflow-x: auto;
}

.leftPanelTable {
  width: 100%;
  font-size: 13px;
  min-width: 800px;
}

.leftPanelTable thead th {
  text-align: left;
  background: #fafafa;
  font-weight: bold;
  vertical-align: middle;
  /* border: 1px solid #ccc; */
  border: 1px solid rgba(0, 0, 0, 0.06);
  text-align: center;
  height: 42px;
  padding: 0 12px;
}

.leftPanelTable tbody tr {
  cursor: pointer;
}

.leftPanelTable tbody td {
  text-align: left;
  padding: 0 12px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.06); */
  /* height: 42px; */
  vertical-align: middle;
  /* line-height: 20px; */
  height: 62.5px; /* 63px */
}

.leftPanelTable tbody td:first-child {
  font-weight: bold;
}

.leftPanelTable tbody tr:hover td {
  background: lightyellow;
}

.itemId {
  display: flex;
  align-items: center;
  font-weight: bold;
  position: relative;
}

.itemIdText {
  margin-left: 3px;
}

.menuButton {
  background: none;
  border: 2px solid #eee;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  border-radius: 6px;
  cursor: pointer;
  background: white;
}

.textCenter {
  text-align: center;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.childItemId {
  margin-right: 6px;
  color: #aaa;
}

.milestoneRow td {
  background: #eee;
}
