.react-split__pane:first-child {
  height: auto !important;
}

.react-split__pane:last-child {
  height: 100% !important;
}

.react-split__sash--vertical {
  width: 3px !important;
  background: #eee !important;
}

.react-split__sash--vertical:hover,
.split-sash-content-active {
  background: #31b6ff !important;
}
