.header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 24px;
  padding-top: auto;
  position: relative;
}

.header .datetime {
  font-size: 18px;
  font-weight: bold;
  min-width: 200px;
  text-align: center;
  text-transform: uppercase;
  color: rgb(207, 108, 88);
}

.calendar {
  border: 2px solid #eff2f3;
  border-radius: 12px;
  overflow: scroll;
}

.week-container {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  border-top: 2px dashed #eff2f3;
  border-bottom: 3px solid #eff2f3;
  color: #7b8989;
}

.week-cell {
  text-align: center;
  padding: 12px;
  font-weight: bold;
  text-transform: uppercase;
  color: #878787;
}

.day-container {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  border-left: 1px solid #eff2f3;
}

.day-cell {
  border-right: 1px solid #eff2f3;
  border-bottom: 1px solid #eff2f3;
  min-height: auto;
  padding: 12px;
  cursor: pointer;
}

.day-cell h2 {
  font-size: 20px;
  font-weight: bold;
  margin: 0;
  padding: 0;
}

.day-cell--faded {
  text-align: center;
}

.day-cell--faded,
.day-cell--faded:hover {
  background: #eff2f363;
  cursor: not-allowed;
}

.day-cell--faded h2 {
  color: #aaa;
}

.day-cell--today h2 {
  font-weight: bold !important;
}

.check-boxes-group {
  margin-top: 6px;
}

.check-boxes-group .ant-checkbox-group-item {
  width: 100%;
  margin: 6px 0;
}
