* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  overflow-x: hidden;
}

body {
  margin: 0;
  background: #eff2f5 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: auto;
}

.site-layout .site-layout-background {
  background: #fff;
  min-height: auto !important;
}

.ant-form-item-control-input-content .ql-toolbar:nth-child(2) {
  display: none;
}

.ql-editor {
  min-height: 120px;
}

.module-card-column .ant-card-bordered {
  border-radius: 16px;
}

.html-cell p {
  margin: 0;
  padding: 0;
}

.ant-picker,
.ant-select-selector,
.ant-form-item-control-input-content input,
.ant-input:not(textarea) {
  height: 38px !important;
}

textarea.ant-input {
  resize: none;
  padding: 10px 12px;
}

.ant-select-selector {
  padding-top: 3px !important;
}

.ant-collapse-header-text {
  font-weight: bold;
}

.quick-talks-search .ant-input {
  height: auto !important;
}

.quick-talks-inbox-message:hover {
  background: #eee !important;
}

.preview-box {
  display: flex !important;
  flex-direction: row;
  width: 100% !important;
  border-radius: 0 !important;
  border: 1px solid #eee !important;
  margin-top: 24px;
}

.preview-box .Image {
  width: 250px;
  border-radius: 0 !important;
  height: 150px;
  object-fit: cover;
}

.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
  height: 0.7rem;
  border-radius: 0.25rem;
  margin-bottom: 0.5rem;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 80%);
  }

  100% {
    background-color: hsl(200, 20%, 90%);
  }
}

.chat-receiver-info {
  padding: 12px;
  border: 1px solid #d0d7de;
  background-color: #f6f8fa;
  border-radius: 6px;
  margin-bottom: 16px;
}

.chat-receiver-info p {
  margin: 0;
  padding: 0;
}

.toolbar {
  margin-bottom: 24px;
}

.toolbar-buttons {
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.toolbar-results {
  margin: 0;
  padding: 0;
}

.faq-answer p {
  margin: 0;
  padding: 0;
}

.ant-select-multiple .ant-select-selector {
  padding-top: 0 !important;
}

.ql-editor[contenteditable="false"] {
  background: #f5f5f5;
}

.disabled-textarea {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border: 1px solid #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
  padding: 12px;
  min-height: 120px;
}

.disabled-textarea * {
  margin: 0;
  padding: 0;
  margin-bottom: 6px;
}

.disabled-textarea *:last-child {
  margin-bottom: 0;
}

.ant-table-tbody>tr:last-child>td {
  border-bottom: none;
}

.printable-form .data_head {
  border-right: 0px solid #707070;
  text-align: left;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  vertical-align: text-top;
  padding: 15px 10px;
}

.printable-form .data_body {
  text-align: left;
  font-size: 14px;
  font-weight: 300;
  vertical-align: text-top;
  border-bottom: 1px solid #186659;
}

.printable-form .h2_heading {
  text-align: left;
  color: #000000;
  font-size: 18px;
  font-weight: 300;
  padding: 5px 0px;
  margin-bottom: 5px;
}

.printable-form .h2_value {
  text-align: left;
  color: #182e82;
  font-size: 18px;
  font-weight: 600;
  padding: 5px 0px;
  margin-bottom: 5px;
}

.printable-form .text-right {
  text-align: right !important;
}

.printable-form .text-left {
  text-align: left !important;
}

.printable-form .text-justify {
  text-align: justify !important;
}

.printable-form .text-center {
  text-align: center !important;
}

.bar-code-container svg {
  width: 100%;
  margin-top: 42px;
}

.ant-collapse-content-box p {
  margin: 0;
  padding: 0;
}

.gantt-modal-button {
  text-align: center;
  border: 1px solid #eee;
  padding: 24px;
  cursor: pointer;
}

.gantt-modal-button:hover {
  border: 1px solid #1890ff;
  background: #e6f7ff;
  color: #1890ff;
}

.autocomplete-search-box .wrapper {
  width: 300px !important;
  z-index: 99 !important;
  position: relative !important;
  border-radius: 0 !important;
  box-shadow: none !important;
}

.autocomplete-search-box .wrapper>div:first-child {
  max-height: 30px !important;
  overflow: hidden !important;
}

.autocomplete-search-box .wrapper:focus-within {
  box-shadow: none !important;
}

.autocomplete-search-box .wrapper>div:last-child li {
  max-height: 42px !important;
  cursor: pointer !important;
  font-size: 15px !important;
}

.autocomplete-search-box .wrapper input {
  font-size: 15px !important;
}

.autocomplete-search-box .clear-icon {
  margin-top: 8px;
}

.ql-toolbar.ql-snow {
  background: white;
}

.ql-editor {
  background: white;
}

.ticket-message * {
  font-size: 15px;
  color: #172b4d !important;
  margin: 0 !important;
  padding: 0 !important;
}

.module-card:hover {
  border: 2px solid #2e4494;
}

.permissions-table {
  width: 100%;
  margin-bottom: 24px;
}

.permissions-table tr th {
  background: #2e4494;
  color: white;
  text-transform: uppercase;
}

.permissions-table tr th:first-child {
  text-align: left;
  width: 160px;
}

.permissions-table tr td:first-child {
  background: #eee;
  font-weight: bold;
}

.permissions-table tr th:not(:nth-child(2)) {
  width: 160px;
  text-align: center;
}

.permissions-table tr th,
.permissions-table tr td {
  border: 1px solid #eee;
  padding: 10px 12px;
}

.permissions-table tr th:first-child,
.permissions-table tr td:first-child {
  border: 1px solid white;
}

.permissions-table tr:hover td {
  background: #eee;
}

.permissions-table tr td:not(:nth-child(2)) {
  text-align: center;
}

.ant-form-item-required {
  width: 100%;
}

.payroll-table {
  width: 100%;
}

.payroll-table th,
.payroll-table td {
  padding: 12px;
}

.payroll-table td:first-child,
.payroll-table td:last-child {
  text-align: center;
  font-weight: bold;
}

.payroll-table tr th {
  color: white;
  font-weight: bold;
}

.payroll-table td,
.payroll-table th {
  border: 1px solid #eee;
}

.payroll-table th:nth-child(2) {
  text-align: left;
}

.earnings-table tr th {
  background-color: #22af46;
}

.deductions-table tr th {
  background-color: #de4848;
}

.pie {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: yellowgreen;
  background-image: linear-gradient(to right, transparent 50%, #655 0);
}

.pie::before {
  content: " ";
  display: block;
  margin-left: 50%;
  height: 100%;
  border-radius: 0 100% 100% 0 / 50%;
  background-color: inherit;
  transform-origin: left;
  /* animation: spin 3s linear infinite,
             bg 6s step-end infinite; */
}

div[data-preset-id="weekAndDayLetter"] {
  min-height: 500vh !important;
}

.b-widget div {
  background-image: none !important;
}

.text-center {
  text-align: center;
}

.text-bold {
  font-weight: bold;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.public-form-table {
  width: 100%;
  margin-bottom: 16px;
}

.public-form-table tr th {
  background: #eee;
}

.public-form-table tr th,
.public-form-table tr td {
  border: 1px solid #eee;
  padding: 6px;
}

.public-form-table tr th {
  text-align: left;
}

.public-form-table tr th:first-child,
.public-form-table tr th:last-child,
.public-form-table tr td:first-child,
.public-form-table tr td:last-child {
  text-align: center;
  width: 100px;
}

.public-form-table {
  width: 100%;
  margin-bottom: 24px;
  page-break-inside: avoid;
}

.public-form-table tr th {
  background: #fbfbfb;
}

.public-form-table tr th,
.public-form-table tr td {
  border: 1px solid #eee;
  padding: 10px;
  text-align: left;
}

.public-form-section {
  page-break-inside: avoid;
}

.public-form-section-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 12px;
}

.public-form-body {
  padding: 24px;
}

.public-form-general-info {
  width: 100%;
  margin-bottom: 16px;
}

.public-form-general-info tr td {
  border: 1px solid #eee;
  padding: 10px;
  text-align: left;
}

.public-form-general-info tr td:first-child {
  background: #fbfbfb;
  font-weight: bold;
  width: 20%;
}

.public-form-row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 24px;
}

.public-form-row td:first-child {
  width: 30% !important;
}

.file-viewer-button {
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.file-viewer-button:hover {
  background: #777;
}

.csv-file-viewer {
  background: white;
  overflow: auto;
  width: 100%;
}

.csv-file-viewer tr th,
.csv-file-viewer tr td {
  border: 1px solid #111;
  text-align: left;
  height: 40px;
}

.csv-file-viewer tr th {
  background: #ccc;
}

.my-drive-search-box .wrapper {
  width: 100%;
  border-radius: 0 !important;
  box-shadow: none !important;
}

.my-drive-search-box.wrapper>div:first-child {
  max-height: 30px !important;
  overflow: hidden !important;
}

.my-drive-search-box .wrapper:focus-within {
  box-shadow: none !important;
}

.my-drive-search-box .wrapper>div:last-child li {
  max-height: 42px !important;
  cursor: pointer !important;
  font-size: 15px !important;
}

.my-drive-search-box .wrapper input {
  font-size: 15px !important;
}

.my-drive-search-box .clear-icon {
  margin-top: 8px;
}

@media print {
  .printable-form .page_break {
    page-break-after: always;
  }

  /* Hide any element with the 'no-print' class during print/PDF generation */
  .no-print {
    display: none !important;
  }
}


@media (max-width: 760px) {
  .autocomplete-search-box .wrapper {
    display: none !important;
  }

  .ant-page-header-heading {
    flex-direction: column;
  }

  .projects-grid {
    grid-template-columns: 1fr !important;
  }
}