.htmlContainer p {
  line-height: 24px;
  padding: 6px 0;
}

.htmlContainer ul {
  margin-left: 32px;
}

.htmlContainer div {
  padding-bottom: 12px;
}
