.rightPanel {
  /* flex: 1; */
  overflow-x: auto;
  overflow-y: hidden;
  height: 100%;
}

.progressBar:hover rect {
  stroke: #31b6ff !important;
  overflow: hidden;
  cursor: pointer;
}
